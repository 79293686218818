import CryptoJS from 'crypto-js';

export const encrypt = (o) =>
  CryptoJS.AES.encrypt(JSON.stringify(o), 'SaLt1!2:3;4').toString();

export const decrypt = (o) => {
  const bytes = CryptoJS.AES.decrypt(o, 'SaLt1!2:3;4');
  try {
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  } catch (exc) {
    return false;
  }
};
