import { useEffect, useState } from 'react';
import Loading from '../components/molecules/Loading';

export default function LazyLoader(props, { delay = 3000 }) {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShow(true);
    }, delay);
    return () => {
      clearTimeout(timeout);
    };
  }, [delay]);

  return show ? <Loading {...props} /> : null;
}
