import { createApi } from '@reduxjs/toolkit/query/react';
import {
  baseQueryWithReauth,
  createBaseQuery,
} from '../../utils/apiUtils';

const baseQuery = createBaseQuery(
  `${process.env.REACT_APP_BACKEND_URL}etiq`,
);

export const apiSlice = createApi({
  reducerPath: 'apiEtiq',
  baseQuery: baseQueryWithReauth(baseQuery),
  tagTypes: [
    'Contracts',
    'Contract',
    'UserEtiqByContracts',
    'UserEtiqByContract',
    'UserEtiq',
    'Emails',
  ],
  endpoints: () => ({}),
});
