import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import Swal from 'sweetalert2';
import { refreshToken } from './axiosApi';

export const createBaseQuery = (baseUrl) =>
  fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem('access_token');
      if (token) {
        headers.set('Authorization', `JWT ${token}`);
      }
      return headers;
    },
  });

export const baseQueryWithReauth =
  (baseQuery) => async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions);

    if (result?.error && result.error.status === 401) {
      const refreshTokenValue = localStorage.getItem('refresh_token');
      if (refreshTokenValue) {
        try {
          const refreshResult = await refreshToken();

          if (refreshResult.status === 200) {
            result = await baseQuery(args, api, extraOptions);
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Session Expired',
              text: 'Your session has expired. Please log in again.',
            });
            window.location.href = '/login';
          }
        } catch (refreshError) {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'An error occurred while refreshing the token.',
          });
          window.location.href = '/login';
        }
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Unauthorized',
          text: 'Please log in again.',
        });
        window.location.href = '/login';
      }
    } else if (result?.error) {
      console.log(result.error);
      Swal.fire({
        icon: 'error',
        title: 'Zut alors...',
      });
    }

    return result;
  };
