import {
  PERMISSION_DROOKY_ADMIN,
  PERMISSION_DROOKY_READONLY,
  PERMISSION_ETIQ_ADMIN,
  PERMISSION_ETIQ_READONLY,
} from '../../utils/constants';
import { useCookies } from 'react-cookie';
import { decrypt } from '../../utils/encrypt';

export const usePermissions = () => {
  // eslint-disable-next-line no-unused-vars
  const [cookies, setCookie] = useCookies(['permissions']);

  const hasPermission = (permission) =>
    cookies?.permissions && cookies?.permissions.length
      ? decrypt(cookies?.permissions)?.[permission]
      : false;

  const isDrookyAdmin = hasPermission(PERMISSION_DROOKY_ADMIN);
  const isDrookyReadonly =
    hasPermission(PERMISSION_DROOKY_READONLY) && !isDrookyAdmin;
  const canAccessToDrooky = isDrookyAdmin || isDrookyReadonly;

  const isEtiqAdmin = hasPermission(PERMISSION_ETIQ_ADMIN);
  const isEtiqReadonly =
    hasPermission(PERMISSION_ETIQ_READONLY) && !isEtiqAdmin;
  const canAccessToEtiq = isEtiqAdmin || isEtiqReadonly;

  return {
    permissions: {
      isDrookyAdmin,
      isDrookyReadonly,
      canAccessToDrooky,
      isEtiqAdmin,
      isEtiqReadonly,
      canAccessToEtiq,
    },
  };
};

export default usePermissions;
